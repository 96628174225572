<template>
    <Teleport :to="`#${containerId}`">
        <div v-if="show || internalShow" ref="dialogRef" class="o365-dialog" :style="[style, { 'z-index': zIndex, 'width': '90%' }]" :class="[
            'shadow',
            size ? `o365-dialog-${size}` : '',
            {
                'fade': !disableFade,
                'show': !disableFade && internalShow && show,
                'movable': !disableMovable,
            },
            $attrs.class]" @mousedown="zIndexCheck" tabindex="-1"
            :data-grid-master-id="masterGrid?.id">
            <div ref="dialogHeader" :class="headerClass">
                <slot name="header" :close="() => show = false" :title="title" :titleTag="titleTag">
                    <component :is="titleTag">{{ title }}</component>
                    <slot name="headerAction"></slot>
                    <button v-if="!noCloseButton" class="btn-close ms-auto" @click="() => show = false"></button>
                </slot>
            </div>
            <slot :close="() => show = false"></slot>
            <div ref="resizeHandle" class="o365-dialog-resize-handle"></div>
        </div>
    </Teleport>
</template>

<script lang="ts">
const CONTAINER_UID = `o365-dialog-container-${crypto.randomUUID()}`;
const BACKDROP_UID = `o365-dialog-backdrop-${crypto.randomUUID()}`;

type DialogOptions = { index: number, updateIndex: any, onBackdropClick?: any, backdrop: boolean };
/** Starting z-index for dialogs */
const DIALOG_ZINDEX = 1055;
/** Open dialogs map. Used to keep track of their z-index and backdrops */
const OPEN_DIALOGS = new Map<string, DialogOptions>();

/**
 * Add dialog options to the global map. Updates z-index to the highest one and if the 
 * dialog has backdrop it will be bound. 
 * @param pId Id of the dialog
 * @param pOnUpdateIndex Function used to communicate new z=index to the dialog instance
 * @param pBackdrop Indicates if the global backdrop should be bound to this dialog
 * @param pOnBackdropClick Callback for backdrop click when this dialog is bound to it
 */
function AddDialog(pId: string, pOnUpdateIndex: any, pBackdrop = false, pOnBackdropClick?: any) {
    const index = DIALOG_ZINDEX + OPEN_DIALOGS.size + 1;
    OPEN_DIALOGS.set(pId, {
        index: index,
        backdrop: pBackdrop,
        onBackdropClick: pOnBackdropClick,
        updateIndex(val) {
            this.index = val;
            pOnUpdateIndex(val)
        }
    });

    if (pBackdrop) {
        BindBackdrop(OPEN_DIALOGS.get(pId)!);
    }
    pOnUpdateIndex(index);
}

/**
 * Focus the given dialog by updating its z-index and binding the global backdrop
 * @param pId Id of the dialog
 */
function FocusDialog(pId: string) {
    const dialog = OPEN_DIALOGS.get(pId);
    if (dialog == null) { return; }
    const toUpdate: string[] = [];
    OPEN_DIALOGS.forEach((value, key) => {
        if (key == pId) { return; }
        if (value.index > dialog.index) {
            toUpdate.push(key);
        }
    });
    toUpdate.forEach(key => {
        const openDialog = OPEN_DIALOGS.get(key)!;
        openDialog.updateIndex(openDialog.index - 1);
    });
    dialog.updateIndex(DIALOG_ZINDEX + OPEN_DIALOGS.size);
    if (dialog.backdrop) {
        BindBackdrop(dialog);
    }
}

/**
 * Remove a dialog from the open map. WIll update all other open dialogs z-index and bind the backdrop to the 
 * one with highest index.
 * @param pId Id of the dialog
 */
function RemoveDialog(pId: string) {
    const dialog = OPEN_DIALOGS.get(pId);
    if (dialog == null) { return; }
    const toUpdate: string[] = [];
    OPEN_DIALOGS.forEach((value, key) => {
        if (key == pId) { return; }
        if (value.index > dialog.index) {
            toUpdate.push(key);
        }
    });
    toUpdate.forEach(key => {
        const openDialog = OPEN_DIALOGS.get(key)!;
        openDialog.updateIndex(openDialog.index - 1);
    });
    OPEN_DIALOGS.delete(pId);
    UpdateBackdrop();
}

/**
 * Create a global backdrop element if not present and bind it to the given dialog.
 * Will set the click handler and update its z-index
 * @param pDialog Dialog options from the open dialogs map
 */
function BindBackdrop(pDialog: DialogOptions) {
    let backdrop = document.getElementById(BACKDROP_UID);
    if (backdrop == null) {
        const container = document.getElementById(CONTAINER_UID)
        if (container == null) { return; }
        backdrop = document.createElement('div');

        backdrop.id = BACKDROP_UID;
        backdrop.className = 'o365-dialog-backdrop';
        container.parentElement?.append(backdrop);
    }
    backdrop.style.zIndex = `${pDialog.index - 1}`;
    backdrop.onclick = pDialog.onBackdropClick;
}

/**
 * Bind the backdrop to an open dialog with the highest index that has backdrop enabled.
 * If no dialogs remain open will clean up the backdrop element
 */
function UpdateBackdrop() {
    const dialog = Array.from(OPEN_DIALOGS.values()).reduce((dialogWithBackdrop, item) => {
        const currentMinIndex = dialogWithBackdrop?.index ?? 0;
        if (item.backdrop && item.index >= currentMinIndex) {
            return item;
        }
        return dialogWithBackdrop;
     }, null as DialogOptions | null);
     if (dialog) {
        BindBackdrop(dialog);
     } else {
        const backdrop = document.getElementById(BACKDROP_UID);
        if (backdrop) {
            backdrop.remove();
        }
     }
}
</script>

<script setup lang="ts">
import { useTarget, useNestedStack } from 'o365-vue-utils';
import { InjectionKeys } from 'o365-utils';
import { onBeforeUnmount, watch, ref, computed, nextTick, inject } from 'vue';
import { addEventListener } from 'o365-vue-utils';
import { createPopper } from 'popper';

export interface IProps {
    /**
     * Unique name for the v-target directive
     */
    name?: string,
    style?: any,
    /**
     * Attach a backdrop to the dialog when its opened. Clicking on this backdrop
     * will close the dialog
     */
    backdrop?: boolean,
    /**
     * Disable closing the dialog on backdrop clicks. Setting this to true
     * also enables backdrop 
     */
    static?: boolean,
    /**
     * Header title of dialog.
     */
    title?: string,
    /**
     * Header title tag. Default is 'h5'
     */
    titleTag?: string,
    /**
     * Additional optional classes on the header container
     */
    headerClass?: string,
    /**
     * Disable fade animations when closing/opening the dialog
     */
    disableFade?: boolean,
    /**
     * Disable autofocus of the dialog on open
     */
    disableFocus?: boolean,
    /**
     * Disable dialog moving when dragging the header
     */
    disableMovable?: boolean,
    /**
     * Disable dialog resize
     */
    disableResize?: boolean,
    /**
     * When opening the dialog reset the position if it was moved by the user
     */
    resetPositionOnOpen?: boolean,
    /**
     * When opening the dialog reset the size if the dialog was resized
     */
    resetSizeOnOpen?: boolean,
    /**
     * Remove the close button from the header
     */
    noCloseButton?: boolean,
    /**
     * Minimum width that is allowed when resizing the dialog
     * @default 400
     */
    minWidth?: number,
    /**
     * Minimum height that is allowed when resizing the dialog
     * @default 200
     */
    minHeight?: number,
    /**
     * Option element that when provided will be used as the popper target. 
     * If the dialog is moved and `resetPositionOnOpen` is not enalbed then next time the dialog
     * is opened it will remain in the moved position.
     */
    target?: HTMLElement,
    /**
     * Size breakpoints similar to bootstrap ones. When not provided then the size equivelent to 'md'.
     * Unlike bootstrap this does set the explicit width, but instead sets the max-width. 
     * Can be overriden with custom classes by using the '--o365-dialog-width' css variable.
     */
    size?: 'sm' | 'lg' | 'xl'
};

const props = withDefaults(defineProps<IProps>(), {
    backdrop: raw => !!raw.static,
    headerClass: 'o365-dialog-header',
    minWidth: 400,
    minHeight: 200,
    titleTag: 'h5'
});
const show = defineModel<boolean>('show');
const internalShow = ref(false);
const resizeHandle = ref<HTMLElement>();

const masterGrid = inject(InjectionKeys.dataGridControlKey, null);

const emit = defineEmits<{
    (e: 'show'): void,
    (e: 'shown'): void,
    (e: 'hide'): void,
    (e: 'hidden'): void,
}>();

if (props.name) {
    useTarget({
        name: props.name,
        handler: () => show.value = true
    });
}

const { currentNest, add: addToNest, remove: removeFromNest } = useNestedStack({
    injectinoKey: InjectionKeys.nestedStackKey,
});

watch(show, (pNewValue) => {
    if (pNewValue) {
        handleShow();
    } else {
        handleHide();
    }
})

const containerId = CONTAINER_UID;
const instanceUid = crypto.randomUUID();
const dialogRef = ref<HTMLElement>();
const dialogHeader = ref<HTMLElement>();
const zIndex = ref(DIALOG_ZINDEX)


let ctKeyboardEvent: (() => void) | null = null;
let ctHeaderDragEvent: (() => void) | null = null;
let ctResizeEvent: (() => void) | null = null;

function zIndexCheck() {
    if (zIndex.value == DIALOG_ZINDEX + OPEN_DIALOGS.size) { return; }
    FocusDialog(instanceUid);
}

function checkForContainer() {
    let container = document.getElementById(containerId)
    if (container == null) {
        container = document.createElement('div');
        container.id = containerId;
        container
        document.body.append(container);
    }
}

function handleShow() {
    AddDialog(instanceUid, (pValue) => {
        zIndex.value = pValue;
    }, props.backdrop, (pEvent) => {
        pEvent.preventDefault();
        if (props.static) { return; }
        show.value = false;
    });

    emit('show');
    nextTick().then(() => {
        const canRestorePosition = !props.resetPositionOnOpen && cX.value && cY.value;
        if (props.target && !canRestorePosition) {
           bindDialogToTarget();
        }
        if (!props.resetSizeOnOpen) {
            restoreSize();
        }
        if (canRestorePosition) {
            restorePosition();
        }
        if (!props.disableMovable && dialogHeader.value) {
            ctHeaderDragEvent = addEventListener(dialogHeader.value, 'mousedown', handleHeaderMouseDown);
        }
        if (!props.disableResize && resizeHandle.value) {
            ctResizeEvent = addEventListener(resizeHandle.value, 'mousedown', handleDragHandleMouseDown);
        }
        if (dialogRef.value) {
            addToNest(dialogRef.value);
            if (!props.disableFocus) {
                dialogRef.value.focus();
            }
            ctKeyboardEvent = addEventListener(dialogRef.value, 'keyup', (pEvent) => {
                if (pEvent.key === 'Escape') {
                    show.value = false;
                }
            });
        }
        if (!props.disableFade) {
            internalShow.value = true;
            return sleep(301);
        }
    }).then(() => {
        emit('shown');
    });
}

function handleHide() {
    removeFromNest();
    RemoveDialog(instanceUid);
    if (ctKeyboardEvent) {
        ctKeyboardEvent();
        ctKeyboardEvent = null;
    }
    if (ctHeaderDragEvent) {
        ctHeaderDragEvent();
        ctHeaderDragEvent = null;
    }
    if (ctResizeEvent) {
        ctResizeEvent();
        ctResizeEvent = null;
    }


    emit('hide');
    if (!props.disableFade) {
        sleep(301).then(() => {
            if (popperInstance) {
                popperInstance.destroy();
                popperInstance = null;
                popperIsAnchored = false;
            }
            internalShow.value = false;
        }).then(() => {
            emit('hidden');
        })
    } else {
        if (popperInstance) {
            popperInstance.destroy();
            popperInstance = null;
            popperIsAnchored = false;
        }
        emit('hidden');
    }
}

checkForContainer();

onBeforeUnmount(() => {
    RemoveDialog(instanceUid);
});

// Dragging
const isDragging = ref(false);

const cX = ref(0);
const cY = ref(0);
const virtualTarget = computed(() => {
    return {
        getBoundingClientRect: () => ({
            width: 0,
            height: 0,
            top: cY.value,
            right: cX.value,
            bottom: cY.value,
            left: cX.value,
        })
    };
});
let popperInstance = null;
let popperIsAnchored = false;
function handleHeaderMouseDown(pEvent: MouseEvent) {
    const target = pEvent.target as HTMLElement;
    if (['INPUT','SELECT','TEXTAREA','BUTTON','A'].includes(target.tagName)) { return; }
    const rect = dialogRef.value?.getBoundingClientRect();
    const xOffset = pEvent.x - (rect?.x ?? 0);
    const yOffset = pEvent.y - (rect?.y ?? 0);
    const updatePosition = (pX: number, pY: number) => {
        const newX = pX - xOffset;
        if (newX >= 0 && newX <= window.innerWidth - (rect?.width ?? 0)) {
            cX.value = newX;
        }
        const newY = pY - yOffset;
        if (newY >= 0 && newY <= window.innerHeight - (rect?.height ?? 0)) {
            cY.value = newY;
        }
    }
    const ctMove = addEventListener(window, 'mousemove', (pEvent) => {
        updatePosition(pEvent.x, pEvent.y);
        popperInstance?.update();
    }, { passive: true });
    window.addEventListener('mouseup', () => { isDragging.value = false; ctMove(); }, { once: true });
    if (popperIsAnchored && popperInstance) {
        popperInstance.destroy();
        popperInstance = null;
        popperIsAnchored = false;
    }
    updatePosition(pEvent.x, pEvent.y);
    if (popperInstance == null) {
        popperInstance = createPopper(virtualTarget.value, dialogRef.value, {
            placement: 'bottom-start',
            // strategy: popperStrategy.value,
            modifiers: [
                {
                    name: 'flip',
                    enabled: false
                },
            ],
        });
    }
    pEvent.preventDefault();
    isDragging.value = true;
}

let cWidth: number | undefined = undefined;
let cHeight: number | undefined = undefined;

function handleDragHandleMouseDown(pEvent: MouseEvent) {
    if (dialogRef.value == null) { return; }
    pEvent.preventDefault();
    const rect = dialogRef.value.getBoundingClientRect();
    const originX = rect.x;
    const originY = rect.y;
    const xBoundry = window.innerWidth - 5;
    const yBoundry = window.innerHeight -5;
    dialogRef.value.style.transform = `translate(${originX}px, ${originY}px)`;
    dialogRef.value.style.margin = '0px';
    dialogRef.value.style.inset = '0px auto auto 0px';
    const updateSize = (pX: number, pY: number) => {
        if (dialogRef.value == null || pX >= xBoundry || pY >= yBoundry) { return; }
        const newWidth = pX - originX;
        if (newWidth >= props.minWidth) {
            cWidth = newWidth;
            dialogRef.value.style.minWidth = `${newWidth}px`;
            dialogRef.value.style.maxWidth = `${newWidth}px`;
        }
        const newHeight = pY - originY;
        if (newHeight >= props.minHeight) {
            cHeight = newHeight;
            dialogRef.value.style.minHeight = `${newHeight}px`;
            dialogRef.value.style.maxHeight = `${newHeight}px`;
        }
    };
    const ctMove = addEventListener(window, 'mousemove', (pEvent) => {
        updateSize(pEvent.x, pEvent.y);
    }, { passive: true });
    window.addEventListener('mouseup', () => { ctMove(); }, { once: true });
}

function sleep(pTime: number) {
    let resolve = () => {};
    const promise = new Promise<void>(res => resolve = res);
    setTimeout(() => {
        resolve();
    }, pTime);
    return promise;
}

function bindDialogToTarget() {
    if (dialogRef.value == null || props.target == null) { return; }
    if (popperInstance) {
        popperInstance.destroy();
    }

    popperInstance = createPopper(props.target, dialogRef.value, {
        placement: 'bottom-start',
        modifiers: [
            {
                name: 'flip',
                enabled: false
            },
        ],
    });
    popperIsAnchored = true;
}

function restorePosition() {
    if (dialogRef.value == null || !cX.value || !cY.value) { return; }    

    dialogRef.value.style.position = 'absolute';
    dialogRef.value.style.inset = '0px auto auto 0px';
    dialogRef.value.style.margin = '0px';
    dialogRef.value.style.transform = `translate(${cX.value}px, ${cY.value}px)`;
}

function restoreSize() {
    if (dialogRef.value == null || !cWidth || !cHeight) { return; }
    const rect = dialogRef.value.getBoundingClientRect();
    dialogRef.value.style.position = 'absolute';
    dialogRef.value.style.inset = '0px auto auto 0px';
    dialogRef.value.style.margin = '0px';
    dialogRef.value.style.transform = `translate(${rect.x}px, ${rect.y}px)`;
    dialogRef.value.style.minWidth = `${cWidth}px`;
    dialogRef.value.style.minHeight = `${cHeight}px`;
}

function showDialog() {
    show.value = true;
}

function hideDialog() {
    show.value = false;
}


defineExpose({ showDialog, hideDialog });

</script>

<style>
.o365-dialog {
    --o365-dialog-width: 500px;
    --o365-dialog-padding: .75rem;
    --o365-dialog-footer-gap: 0.5rem;
    border-radius: 0;

    display: flex;
    flex-direction: column;
    
    position: absolute;
    transform: translateX(-50%);
    left: 50%;
    top: 5%;
    outline: none;

    maX-width: var(--o365-dialog-width);
    max-height: calc(95vh - var(--o365-app-container-top));
    background-color: var(--bs-body-bg);
}

.o365-dialog-resize-handle {
    position: absolute;
    cursor: se-resize;
    right: -5px;
    bottom: -5px;
    width: 10px;
    height: 10px;
}

.o365-dialog.movable .o365-dialog-header {
    user-select: none;
}

.o365-dialog-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    align-self: stretch;
    padding: var(--o365-dialog-padding)!important;
    border-bottom: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
    background-color: rgb(var(--bs-primary-rgb));
    color: white;
}

.o365-dialog-header .btn-close  {
    filter: invert(1) grayscale(100%) brightness(200%);
}

.o365-dialog-body {
    padding: var(--o365-dialog-padding)!important;
    background-color: var(--bs-light);
    overflow-y: auto;
    flex-grow: 1;
}

.o365-dialog-footer {
    display: flex;
    flex-shrink: 0;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-end;

    padding: calc(var(--o365-dialog-padding) - var(--o365-dialog-footer-gap)* .5);
    border-top: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.o365-dialog-footer * {
    margin: calc(var(--o365-dialog-footer-gap) *  .5);
}

.o365-dialog-sm {
    --o365-dialog-width: 400px
}

.o365-dialog-lg {
    --o365-dialog-width: 800px
}

.o365-dialog-xl {
    --o365-dialog-width: 1140px
}

.o365-dialog-backdrop {
    opacity: 0.5;
    background-color: black;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
}
</style>